import camelize from 'camelcase-keys'
import decamelize from 'decamelize-keys'
import { ZodSchema, z } from 'zod'

export function isObject(obj: unknown): obj is object {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export function camelizeSchema<T extends ZodSchema>(
  schema: T,
  options?: { deep?: boolean; stopPaths?: string[] },
) {
  return z.preprocess((data) => {
    if (isObject(data) || Array.isArray(data)) {
      return camelize(
        data as Record<string, unknown> | Record<string, unknown>[],
        {
          deep: true,
          ...options,
        },
      )
    }

    throw new Error('unable to camelize data, it must be an object or array')
  }, schema)
}

export type Decamelize<T extends readonly unknown[] | Record<string, unknown>> =
  ReturnType<typeof decamelize<T>>
