import { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'

import SyncEventBusManager from '@/offline/managers/SyncEventBusManager'
import { SYNC_EVENT_TYPES } from '@/offline/interfaces/SyncEventBusManagerInterface'
import { DefaultLocalDataManager } from '@/offline/managers/DefaultLocalDataManager'
import { config as offlineConfig } from '@/offline/config'
import { useIsOnline } from '@/offline/hooks/useIsOnline'

import { SYNC_STATUS } from '../config'

export const useListenSyncData = () => {
  const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"

  const { isOnline } = useIsOnline()
  const [syncStatus, setSyncStatus] = useState(
    isOnline ? SYNC_STATUS.SYNCED : SYNC_STATUS.NO_CONNECTION,
  )
  const localDataManager = useMemo(
    () =>
      new DefaultLocalDataManager(
        offlineConfig.DB_NAME,
        offlineConfig.DB_VERSION,
      ),
    [],
  )

  const syncBus = useMemo(() => new SyncEventBusManager(), [])

  useEffect(() => {
    syncBus.addEventListener(SYNC_EVENT_TYPES.START, () => {
      setSyncStatus(SYNC_STATUS.SYNCING)
    })
    syncBus.addEventListener(SYNC_EVENT_TYPES.SUCCESS, () => {
      localStorage.setItem('last-sync', format(new Date(), dateFormat))
      setSyncStatus(SYNC_STATUS.SYNCED)
    })
    syncBus.addEventListener(SYNC_EVENT_TYPES.ERROR, () => {
      setSyncStatus(SYNC_STATUS.CONNECTION_TIMEOUT)
    })

    window.addEventListener('offline', () => {
      setSyncStatus(SYNC_STATUS.NO_CONNECTION)
    })
    window.addEventListener('online', () => {
      setSyncStatus(SYNC_STATUS.SYNCED)
    })
  }, [syncBus])

  return { syncStatus, syncBus, localDataManager }
}
