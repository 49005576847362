import { z } from 'zod'

import { treatmentPlanSchema } from '@/tp/models/treatment-plan'
import { freeOperantSchema } from '@/tp/models/free-operants'
import { programSchema } from '@/core/models/program'

export const staffSchema = z.object({
  id: z.string(),
  initials: z.string().nullable(),
})

export const placeOfServiceSchema = z.object({
  id: z.string(),
  posCodeTitle: z.string().nullable(),
  street: z.string().nullable(),
  street2: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zipCode: z.string().nullable(),
})

export const sessionSchema = z.object({
  timestamp: z.string().optional(),
  id: z.string(),
  user: z.string().optional(),
  scheduledStartAt: z.string(),
  scheduledEndAt: z.string(),
  startAt: z.string().nullable(),
  endAt: z.string().nullable(),
  summary: z.string().nullable(),
  supervisorSummary: z.string().nullable(),
  state: z.string().nullable(),
  cancelComment: z.string().nullable(),
  systemEnded: z.boolean().nullable(),
  placeOfService: placeOfServiceSchema,
  treatmentPlan: treatmentPlanSchema,
  staff: staffSchema.nullable(),
  soapNoteStatus: z.string().nullable(),
})

export const clientSchema = z.object({
  id: z.string(),
  user: z.string().optional(),
  firstName: z.string(),
  middleName: z.string().optional(),
  lastName: z.string(),
  gender: z.string().nullable(),
  ethnicity: z.string().nullable(),
  notes: z.string().nullable(),
  dob: z.string().nullable(),
})

export const iseClientSchema = z.object({
  id: z.string(),
  mrn: z.number(),
  initials: z.string(),
  avatarUrl: z.string(),
  shortFullName: z.string(),
})

// TODO: We should use this when defining the event payload of an offlineDbSession, but
// we'll need to flush out the schema first.
export const offlineEventPayloadSchema = z.object({
  eventType: z.string(),
})

export const offlineDbSession = z.object({
  id: z.string(),
  event: z.object({}),
})

export enum Stores {
  Session = 'sessions',
  Client = 'clients',
  Ise = 'ise',
  IseEvents = 'ise_events',
  SymmetricKey = 'symmetric_key',
}

export enum Indexes {
  User = 'user',
  Uuid = 'uuid',
  Id = 'id',
}

const routesSchema = z.object({
  online: z.string(),
  offline: z.string(),
})

const fallbackRoutesSchema = z.record(z.string(), routesSchema)

export const offlineEventSchema = z.object({
  uuid: z.string(),
  event: z.string(),
  sessionId: z.string().uuid(),
})

const tableDataSchema =
  offlineEventSchema ||
  sessionSchema ||
  freeOperantSchema ||
  programSchema ||
  undefined

const storesEnumSchema = z.nativeEnum(Stores)

const indexDbRegistrySchema = z.object({
  table: storesEnumSchema,
  data: tableDataSchema,
})

const gatheredDataSchema = z
  .array(indexDbRegistrySchema)
  .or(indexDbRegistrySchema)

export type Routes = z.infer<typeof routesSchema>

export type FallbackRoutes = z.infer<typeof fallbackRoutesSchema>

export type TableData = z.infer<typeof tableDataSchema>

export type IndexDbRegistry = z.infer<typeof indexDbRegistrySchema>

export type GatheredData = z.infer<typeof gatheredDataSchema>

export type Session = z.infer<typeof sessionSchema>

export type Client = z.infer<typeof clientSchema>

export type ISEClient = z.infer<typeof iseClientSchema>

export type OfflineEvent = z.infer<typeof offlineEventSchema>
