import i18n from 'i18next'

import { SyncStatus } from './types'

export const SYNC_STATUS: SyncStatus = {
  SYNCED: { title: i18n.t('Your data is synced'), icon: 'cloud_done' },
  SYNCING: { title: i18n.t('Currently syncing...'), icon: 'cloud_sync' },
  NO_CONNECTION: { title: i18n.t('You`re offline'), icon: 'cloud_off' },
  CONNECTION_TIMEOUT: {
    title: i18n.t('Connection timeout'),
    icon: 'cloud_error',
  },
}
