import { BackgroundSyncManagerInterface } from '../interfaces/BackgroundSyncManagerInterface'
import { SymmetricKeyManagerInterface } from '../interfaces/SymmetricKeyManagerInterface'
import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'

import SyncEventBusManager from './SyncEventBusManager'

const RETRYABLE_STATUS_CODES = new Set([408, 425, 429, 500, 502, 503, 504])
const fetchAfterTimeout = (
  url: string,
  timeout: number,
  fetchOptions: RequestInit = { cache: 'reload' },
): Response | PromiseLike<Response> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      fetch(url, fetchOptions).then(resolve).catch(reject)
    }, timeout)
  })
}
const fetchWithRetry = async (url: string, retryAttempts: number) => {
  let count = 0
  let timeout = 1000
  let resp

  try {
    resp = await fetch(url)
  } catch {
    timeout *= 2
    count++
  }

  // retry if fetch throws
  while (!resp && count < retryAttempts) {
    try {
      resp = await fetchAfterTimeout(url, timeout)
    } catch {
      timeout *= 2
      count++
    }
  }

  if (!resp) {
    throw new Error(`Failed to fetch after ${retryAttempts} attempts`)
  }

  // retry if the request failed due to a retryable status code
  while (RETRYABLE_STATUS_CODES.has(resp.status) && count < retryAttempts) {
    resp = await fetchAfterTimeout(url, timeout)

    timeout *= 2
    count++
  }

  return resp
}

export default class BackgroundSyncManager
  implements BackgroundSyncManagerInterface
{
  #interval: number
  #intervalId: number | null
  #syncEventBusManager: SyncEventBusManagerInterface
  #retryAttempts: number
  #symmetricKeyManager: SymmetricKeyManagerInterface

  constructor(
    symmetricKeyManager: SymmetricKeyManagerInterface,
    interval = 1000 * 60 * 60,
    retryAttempts = 3,
  ) {
    this.#interval = interval
    this.#intervalId = null
    this.#syncEventBusManager = new SyncEventBusManager()
    this.#retryAttempts = retryAttempts
    this.#symmetricKeyManager = symmetricKeyManager

    this.#syncEventBusManager.addEventListener('RETRY', async () => {
      this.#syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.START, {
          detail: { message: 'starting background sync' },
        }),
      )

      try {
        await this.#syncSessions()
        await this.#syncLogout()
        await this.#syncIntervalChimeAudio()
      } catch (e) {
        this.#syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
            detail: { error: e },
          }),
        )
      }

      this.#syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
          detail: { message: 'background sync completed successfully' },
        }),
      )
    })
  }

  startBackgroundSync() {
    this.#syncEventBusManager.dispatch(
      new CustomEvent(SYNC_EVENT_TYPES.START, {
        detail: { message: 'starting background sync' },
      }),
    )

    this.#syncWaitingPage()

    // DBW 6/26/24 -- the login page tries to fetch the symmetric key as well
    // but users don't have creds and the check fails, so we need to catch it.
    // We also report the failure to a banner, which doesn't exist on the login
    // page, in case there are errors on a page that does have the banner.
    try {
      this.#syncSymmetricKey()
    } catch (e) {
      this.#syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { error: e },
        }),
      )
    }

    this.#syncEventBusManager.dispatch(
      new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
        detail: { message: 'background sync completed successfully' },
      }),
    )

    // start background sync
    this.#intervalId = window.setInterval(() => {
      this.#syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.START, {
          detail: { message: 'starting background sync' },
        }),
      )

      this.#syncSessions()

      this.#syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
          detail: { message: 'background sync completed successfully' },
        }),
      )
    }, this.#interval)
  }

  stopBackgroundSync() {
    if (this.#intervalId) {
      clearInterval(this.#intervalId)
      this.#intervalId = null
    } else {
      console.warn(
        'attempted to stop background sync when background sync was not running',
      )
    }
  }

  async #syncSessions() {
    // get sessions page from the server
    const resp = await fetchWithRetry('/sessions/', this.#retryAttempts)

    // if the request failed, report to the end user
    if (resp.status >= 400) {
      this.#syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { message: 'Failed to sync sessions page' },
        }),
      )
      return
    }

    // repopulate the sessions page in the http cache
    const cache = await caches.open('twyll-offline')
    cache.put('/sessions/', resp.clone())
  }

  async #syncLogout() {
    // get logout page from the server
    const [htmlResp, jsResp] = await Promise.all([
      fetchWithRetry('/accounts/logout/', this.#retryAttempts),
      fetchWithRetry('/static/js/logout.js', this.#retryAttempts),
    ])

    // repopulate the logout page in the http caches
    const [cacheTwyllOffline, cacheStaticResources] = await Promise.all([
      caches.open('twyll-offline'),
      caches.open('static-resources'),
    ])
    cacheTwyllOffline.put('/accounts/logout/', htmlResp.clone())
    cacheStaticResources.put('/static/js/logout.js', jsResp.clone())
  }

  async #syncWaitingPage() {
    const bundleHash = process.env.BUNDLE_HASH // 'bundle' || 'v4uuid'
    const routeWaitingJsBundle = `/static/assets/js/waiting.${bundleHash}.js`

    const html = await fetchWithRetry('/sessions/waiting', this.#retryAttempts)
    const reactApp = await fetchWithRetry(
      routeWaitingJsBundle,
      this.#retryAttempts,
    )
    const translations = await fetchWithRetry(
      '/static/locales/en-us/offline.json',
      this.#retryAttempts,
    )

    const [cacheTwyllOffline, cacheStaticResources] = await Promise.all([
      caches.open('twyll-offline'),
      caches.open('static-resources'),
    ])

    cacheTwyllOffline.put('/session/waiting', html.clone())
    cacheStaticResources.put(routeWaitingJsBundle, reactApp.clone())
    cacheStaticResources.put(
      '/static/locales/en-us/offline.json',
      translations.clone(),
    )
  }

  async #syncIntervalChimeAudio() {
    //get audio from the server
    const chime = await fetchWithRetry(
      '/static/audio/chime.wav',
      this.#retryAttempts,
    )

    const cache = await caches.open('twyll-offline')
    cache.put('/static/audio/chime.wav', chime.clone())
  }

  async #syncSymmetricKey() {
    // DBW 6/21/24 -- this returns the key, but we only need to refresh the cache,
    // so we drop the return value on the floor
    try {
      await this.#symmetricKeyManager.fetchAndCache()
    } catch (e) {
      this.#syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { error: e },
        }),
      )
    }
  }
}
