import { useEffect, useState } from 'react'

import { IS_SAFARI } from '@/offline/managers/constant'

import heartbeatManager from '../managers/HeartbeatManager'

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState<boolean>(heartbeatManager.isOnline)

  useEffect(() => {
    const onlineListener = () => setIsOnline(true)
    const offlineListener = () => setIsOnline(false)

    if (IS_SAFARI) {
      heartbeatManager.observe(setIsOnline)
    } else {
      window.addEventListener('online', onlineListener)
      window.addEventListener('offline', offlineListener)
    }
    return () => {
      if (IS_SAFARI) {
        heartbeatManager.removeObserver(setIsOnline)
      } else {
        window.removeEventListener('online', onlineListener)
        window.removeEventListener('offline', offlineListener)
      }
    }
  }, [])

  return { isOnline }
}
