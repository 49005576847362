import { OfflineApplication } from '@/offline/OfflineApplication'
import { config } from '@/offline/config'
import { DefaultLocalDataManager } from '@/offline/managers/DefaultLocalDataManager'
import { DefaultOfflineManager } from '@/offline/managers/DefaultOfflineManager'

import BackgroundSyncManager from './managers/BackgroundSyncManager'
import SymmetricKeyManager from './managers/SymmetricKeyManager'

let appInstance: OfflineApplication | null = null

// Function to initialize the OfflineApplication instance
const initializeAppInstance = (): OfflineApplication => {
  const localDataManager = new DefaultLocalDataManager(
    config.DB_NAME,
    config.DB_VERSION,
  )
  const symmetricKeyManager = new SymmetricKeyManager(localDataManager)
  const offlineManager = new DefaultOfflineManager(
    symmetricKeyManager,
    localDataManager,
  )
  const backgroundSyncManager = new BackgroundSyncManager(
    symmetricKeyManager,
    config.SYNC_INTERVAL,
  )
  try {
    backgroundSyncManager.startBackgroundSync()
  } catch (error) {
    // DBW 5/15/2024: We may be offline or logged out, in which case the background sync will fail.
    // We can ignore this error and continue for now, but in the future we should schedule
    // background syncing to restart once we rejoin the network or login.
    console.error('Error starting background sync:', error)
  }

  return new OfflineApplication(offlineManager, backgroundSyncManager)
}

// Promise to handle the asynchronous initialization
let resolveInit: (app: OfflineApplication) => void
export const appInitialized: Promise<OfflineApplication> = new Promise(
  (resolve) => {
    resolveInit = resolve
  },
)

// get singleton
export const getOfflineApp = (): OfflineApplication | null => {
  if ((window as any).offline_mode === 'True') {
    if (!appInstance) {
      appInstance = initializeAppInstance()
      appInstance
        .init()
        .then(() => {
          console.log('App initialized')
          resolveInit(appInstance as OfflineApplication) // Resolve the appInitialized promise
        })
        .catch((error) => {
          console.error('An error occurred during app initialization:', error)
        })
    }
    return appInstance
  }
  return null
}

export const initOfflineApp = (): void => {
  const shouldStartOffline = (window as any).offline_mode === 'True'

  // Feature flag for offline mode
  if (shouldStartOffline) {
    window.addEventListener('load', () => {
      getOfflineApp() // initialize app if not already initialized
    })
  }
}
