import { CryptographyServiceInterface } from '../interfaces/CryptographyServiceInterface'

const decodeHex = (string: string) => {
  const uint8array = new Uint8Array(Math.ceil(string.length / 2))

  for (let i = 0; i < string.length; ) {
    uint8array[i / 2] = Number.parseInt(string.slice(i, (i += 2)), 16)
  }

  return uint8array
}

/**
 * Generates a new RSA key pair using the specified parameters.
 * @returns {Promise<CryptoKeyPair>} A Promise that resolves to a CryptoKeyPair containing the generated public and private keys.
 * @throws {Error} If key generation fails or is not supported.
 */
export const generateKeyPair: CryptographyServiceInterface['generateKeyPair'] =
  () => {
    return crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      false,
      ['wrapKey', 'unwrapKey'],
    )
  }

/**
 * Generates a new symmetric encryption key using the specified parameters.
 * @returns {Promise<CryptoKey>} A Promise that resolves to a CryptoKey representing the generated symmetric encryption key.
 * @throws {Error} If key generation fails or is not supported.
 */
export const generateSymmetricKey: CryptographyServiceInterface['generateSymmetricKey'] =
  (extractable = false) => {
    return crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256,
      },
      extractable,
      ['encrypt', 'decrypt'],
    )
  }

/**
 * Converts a string into a BufferSource, we are interested in Uint8Array particularly.
 *
 * @param {string} data - The string to convert into binary data.
 * @returns {Uint8Array} A binary representation of the input string.
 */
export const stringToBufferSource: CryptographyServiceInterface['stringToBufferSource'] =
  (data: string) => {
    const textEncoder = new TextEncoder()

    // DBW 5/15/2023: We need this for unit testing, because the nodejs uint8array and the jsdom uint8array are different types
    return new Uint8Array(textEncoder.encode(data))
  }

/**
 * Converts a string to an ArrayBuffer.
 *
 * @param {string} data - The input string to be converted to ArrayBuffer.
 * @returns {Uint8Array} - The resulting ArrayBuffer containing the binary representation of the input string.
 */
export const stringToUint8Array: CryptographyServiceInterface['stringToUint8Array'] =
  (data: string) => {
    const buffer = new ArrayBuffer(data.length)
    const bufferView = new Uint8Array(buffer)
    for (let i = 0; i < data.length; i++) {
      bufferView[i] = data.charCodeAt(i)
    }
    return bufferView
  }

/**
 * Encrypts data symmetrically using AES-GCM encryption with a provided symmetric key.
 *
 * @param {CryptoKey} symmetricKey - The symmetric key used for encryption.
 * @param {string} data - The data to encrypt.
 * @returns {Promise<string>} A promise that resolves with the encrypted data.
 */
export const encryptSymmetrically: CryptographyServiceInterface['encryptSymmetrically'] =
  async (symmetricKey: CryptoKey, message: string) => {
    const encoder = new TextEncoder()
    const data = encoder.encode(message)

    const iv = crypto.getRandomValues(new Uint8Array(16))
    const encrypted = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      symmetricKey,
      data,
    )
    const combined = new Uint8Array(iv.length + encrypted.byteLength)
    combined.set(iv)
    combined.set(new Uint8Array(encrypted), iv.length)

    return btoa(String.fromCharCode(...combined))
  }

/**
 * Decrypts data symmetrically using AES-GCM decryption with a provided symmetric key.
 *
 * @param {CryptoKey} symmetricKey - The symmetric key used for decryption.
 * @param {string} ciphertext - The binary data to decrypt, including IV and encrypted data.
 * @returns {Promise<string>} A promise that resolves with the decrypted data.
 */
export const decryptSymmetrically: CryptographyServiceInterface['decryptSymmetrically'] =
  async (symmetricKey: CryptoKey, ciphertext: string): Promise<string> => {
    const iv = new Uint8Array(
      Array.from(atob(ciphertext).slice(0, 16)).map((ch) => ch.charCodeAt(0)),
    )
    const encryptedData = new Uint8Array(
      Array.from(atob(ciphertext).slice(16)).map((ch) => ch.charCodeAt(0)),
    )

    const decryptedData = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: iv },
      symmetricKey,
      encryptedData,
    )

    return new TextDecoder().decode(decryptedData)
  }

/**
 * Converts a binary data buffer (e.g., ArrayBuffer, TypedArray) to a string using TextDecoder.
 *
 * @param {BufferSource} data - The data to convert to a string.
 * @returns {string} The decoded string.
 */
export const bufferSourceToString: CryptographyServiceInterface['bufferSourceToString'] =
  (data: BufferSource) => {
    const textDecoder = new TextDecoder()
    return textDecoder.decode(data)
  }

/**
 * Converts an ArrayBuffer to a string.
 *
 * @param {ArrayBuffer} data - The input ArrayBuffer to be converted to a string.
 * @returns {string} - The resulting string representation of the binary data in the input ArrayBuffer.
 */

export const arrayBufferToString: CryptographyServiceInterface['arrayBufferToString'] =
  (data: ArrayBuffer) => {
    return String.fromCharCode.apply(null, Array.from(new Uint8Array(data)))
  }

/**
 * Wraps a CryptoKey using a wrapping key with RSA-OAEP encryption.
 *
 * @param keyToWrap - The CryptoKey to be wrapped.
 * @param wrappingKey - The CryptoKey used for wrapping.
 * @returns A Promise that resolves to the wrapped key.
 */
export const wrapKey: CryptographyServiceInterface['wrapKey'] = async (
  keyToWrap: CryptoKey,
  wrappingKey: CryptoKey,
) => {
  return await crypto.subtle.wrapKey('raw', keyToWrap, wrappingKey, 'RSA-OAEP')
}

/**
 * Unwraps a wrapped key using an unwrapping key with RSA-OAEP encryption and AES-GCM key derivation.
 *
 * @param wrappedKey - The wrapped key as an ArrayBuffer.
 * @param unwrappingKey - The CryptoKey used for unwrapping.
 * @returns A Promise that resolves to the unwrapped key.
 */
export const unwrapKey: CryptographyServiceInterface['unwrapKey'] = async (
  wrappedKey: ArrayBuffer,
  unwrappingKey: CryptoKey,
) => {
  return await crypto.subtle.unwrapKey(
    'raw',
    wrappedKey,
    unwrappingKey,
    'RSA-OAEP',
    'AES-GCM',
    true,
    ['encrypt', 'decrypt'],
  )
}

/**
 * Encrypts data using a hybrid encryption approach.
 *
 * @param {CryptoKey} publicKey - The recipient's public key used for asymmetric encryption.
 * @param {any} data - The data to be encrypted. (At the moment tested with string)
 */
export const encryptHybrids: CryptographyServiceInterface['encryptHybrids'] =
  async (publicKey: CryptoKey, data: string) => {
    const symmetricKey = await generateSymmetricKey(true)
    const encryptedData = await encryptSymmetrically(symmetricKey, data)
    const wrappedKey = await wrapKey(symmetricKey, publicKey)
    return new Promise((resolve, reject) => {
      resolve({ encryptedData: encryptedData, wrappedKey: wrappedKey })
      reject('Error encrypting hybrids')
    })
  }

/**
 * Decrypts data that was encrypted using a hybrid encryption approach.
 *
 * @param {CryptoKey} privateKey - The recipient's private key used for asymmetric decryption.
 * @param {ArrayBuffer} wrappedKey - The wrapped symmetric key used for encryption.
 * @param {any} data - The encrypted data to be decrypted.
 * @returns {Promise<string>} A promise that resolves to the decrypted data as a string.
 */
export const decryptHybrids: CryptographyServiceInterface['decryptHybrids'] =
  async (
    privateKey: CryptoKey,
    wrappedKey: ArrayBuffer,
    data: any,
  ): Promise<string> => {
    const unwrappedKey = await unwrapKey(wrappedKey, privateKey)
    const decryptedData = await decryptSymmetrically(unwrappedKey, data)

    return Promise.resolve(decryptedData)
  }

/**
 * Exports a cryptographic key as a string.
 *
 * @param {CryptoKey} key - The cryptographic key to be exported.
 * @returns {Promise<string>} - A promise that resolves to the string representation of the exported key.
 */
export const exportCryptoKey: CryptographyServiceInterface['exportCryptoKey'] =
  async (key) => {
    const exportedKey = await crypto.subtle.exportKey('raw', key)
    return arrayBufferToString(exportedKey)
  }

/**
 * Imports a cryptographic key from its string representation.
 *
 * @param {string} key - The string representation of the key to be imported.
 * @returns {Promise<CryptoKey>} - A promise that resolves to the imported cryptographic key.
 */
export const importCryptoKey: CryptographyServiceInterface['importCryptoKey'] =
  async (key) => {
    const keyBuffer = decodeHex(key)
    return crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-GCM' },
      false,
      ['encrypt', 'decrypt'],
    )
  }

/**
 * Encrypts values in the provided data object or array using a symmetric key.
 *
 * @param {any} data - The data object or array to be encrypted.
 * @param {CryptoKey} symmetricKey - The symmetric key used for encryption.
 * @returns {Promise<any>} - A promise that resolves to the encrypted data.
 */
export const encryptValues: CryptographyServiceInterface['encryptValues'] =
  async (data: any, symmetricKey: CryptoKey): Promise<any> => {
    if (Array.isArray(data)) {
      // Encrypt each item in the array
      return await Promise.all(
        data.map((item) => encryptValues(item, symmetricKey)),
      )
    } else if (typeof data === 'object' && data !== null) {
      const encryptedObject: Record<string, any> = {}
      for (const key of Object.keys(data)) {
        // Skip encryption for the keys 'id' and 'user'
        if (key === 'id' || key === 'user') {
          encryptedObject[key] = data[key]
        } else {
          const encryptedValue = await encryptValues(data[key], symmetricKey)
          encryptedObject[key] = encryptedValue
        }
      }
      return encryptedObject
    } else {
      // Encrypt leaf values
      const stringifiedData = JSON.stringify(data)
      const encryptedData = await encryptSymmetrically(
        symmetricKey,
        stringifiedData,
      )

      return encryptedData
    }
  }

/**
 * Decrypts values in the provided data object or array using a symmetric key.
 *
 * @param {any} data - The data object or array to be decrypted.
 * @param {CryptoKey} symmetricKey - The symmetric key used for decryption.
 * @returns {Promise<any>} - A promise that resolves to the decrypted data.
 */
export const decryptValues: CryptographyServiceInterface['decryptValues'] =
  async (data: any, symmetricKey: CryptoKey): Promise<any> => {
    if (Array.isArray(data)) {
      // Decrypt each item in the array
      return await Promise.all(
        data.map((item) => decryptValues(item, symmetricKey)),
      )
    } else if (typeof data === 'object' && data !== null) {
      const decryptedObject: Record<string, any> = {}
      for (const key of Object.keys(data)) {
        // Skip decryption for the keys 'id' and 'user'
        if (key === 'id' || key === 'user') {
          decryptedObject[key] = data[key]
        } else {
          const decryptedValue = await decryptValues(data[key], symmetricKey)
          decryptedObject[key] = decryptedValue
        }
      }
      return decryptedObject
    } else {
      // Check if the data is an encrypted string
      if (typeof data === 'string') {
        const decryptedBuffer = await decryptSymmetrically(symmetricKey, data)
        return JSON.parse(decryptedBuffer)
      }
      return data // Return as is if not encrypted
    }
  }
