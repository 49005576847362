import React, { useState } from 'react'

import { SYNC_EVENT_TYPES } from '@/offline/interfaces/SyncEventBusManagerInterface'
import { useFeatureIsOn } from '@/core/services/growthbook'
import I18nWrapper from '@/core/components/I18nWrapper'
import { Icon } from '@/core/components/icons/Icon'

import { SyncCard } from './SyncCard'
import { useListenSyncData } from './hooks/useListenSyncData'
import { SYNC_STATUS } from './config'

export const Sync = () => {
  const [isSyncOpen, setIsSyncOpen] = useState(false)
  const { syncStatus, localDataManager, syncBus } = useListenSyncData()
  const isOfflineEnabled = useFeatureIsOn('coffee_ginger_offline_mode')
  const csrfToken = window.CSRFTOKEN

  if (isOfflineEnabled) {
    return null
  }

  const onOpenSyncCard = () => {
    setIsSyncOpen(!isSyncOpen)
  }

  const handleSyncData = async () => {
    await localDataManager.initDB()
    await localDataManager.removeExpiredData()
    await localDataManager.drainQueue({ csrfToken })
    syncBus.dispatch(
      new CustomEvent(SYNC_EVENT_TYPES.RETRY, {
        detail: { message: 'retrying background sync' },
      }),
    )
  }

  return (
    <I18nWrapper>
      <button
        className="flex justify-center w-40 h-40 pt-8 btn-icon hover:bg-gray-200"
        onClick={onOpenSyncCard}
        aria-label="Sync status icon"
      >
        <Icon icon={syncStatus.icon} />
      </button>
      <SyncCard
        isVisible={isSyncOpen}
        disabled={syncStatus === SYNC_STATUS.SYNCING}
        title={syncStatus.title}
        handleSyncData={handleSyncData}
      />
    </I18nWrapper>
  )
}
