/**
 * This module is used to initialize global services that must always be
 * initialized before other application modules.
 */
import { config } from '@/core/config'

import { ErrorMonitoring } from './services/error-monitoring'

if (config.enableErrorReporting) {
  ErrorMonitoring.init()
}
