import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'

const bus = new EventTarget()

export default class SyncEventBusManager
  implements SyncEventBusManagerInterface
{
  dispatch(e: Event) {
    bus.dispatchEvent(e)
  }

  addEventListener(type: keyof typeof SYNC_EVENT_TYPES, callback: () => void) {
    bus.addEventListener(type, callback)
  }
}
