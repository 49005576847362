const parsedTraceSampleRate = parseFloat(process.env.TRACE_SAMPLE_RATE || '')

export const config = {
  mode: process.env.NODE_ENV,
  i18nVersions: process.env.LOCALE_VERSIONS,
  growthbookClientKey: window.GROWTHBOOK_API_KEY,
  enableErrorReporting:
    process.env.NODE_ENV === 'production' ||
    process.env.SENTRY_DEBUG === 'true',
  sentryDsn: process.env.SENTRY_DSN_FRONTEND,
  sentryEnvironment: window.SENTRY_ENVIRONMENT,
  traceSampleRate: isNaN(parsedTraceSampleRate) ? 0 : parsedTraceSampleRate,
} as const
