export enum SYNC_EVENT_TYPES {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  START = 'START',

  // Triggered by the user to indicate the system should retry
  RETRY = 'RETRY',
}

export interface SyncEventBusManagerInterface {
  dispatch(e: Event): void
  addEventListener(
    type: keyof typeof SYNC_EVENT_TYPES,
    callback: () => void,
  ): void
}
